import { IconCheck, IconX } from '@tabler/icons';
import { Button, Form, Input, message } from 'antd';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { myProjectsTeamsTimesheetsActions } from 'store/my-projects-teams-timesheets';

export const PDComment = ({ tpaid: tpaId, initialComment ,isMaster}) => {
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const [commentError, setCommentError] = useState(false);
  console.error('46876594759754',isMaster)
 
  const submitfunction = (comments) => {
    dispatch(
      myProjectsTeamsTimesheetsActions.getPendingProjectTeamsTimesheet({
        tpaId,
        comments,
        isMaster
      })
    );
  };

  const rejectfunction = (comments) => {
    if (comments) {
      dispatch(
        myProjectsTeamsTimesheetsActions.RejectPendingProjectTeamsTimesheets({
          tpaId,
          comments,
        })
      );
      setCommentError(false); 
    } else {
      messageApi.error('Please provide a comment');
      setCommentError(true); 
      form.setFields([
        {
          name: 'comments',
          errors: ['Comment is required to reject!'],
        },
      ]);
    }
  };

  const handleSubmit = () => {
    const comments = form.getFieldValue('comments');
    submitfunction(comments);
  };

  const handleReject = () => {
    const comments = form.getFieldValue('comments');
    rejectfunction(comments);
  };

  return (
    <>
      {contextHolder}
      <Form
        form={form}
        initialValues={{ comments: initialComment || '' }}
        style={{ position: 'relative' }}
      >
        <Form.Item
          name="comments"
          validateStatus={commentError ? 'error' : ''}
          help={commentError ? 'Please provide a comment' : ''}
        >
          <Input.TextArea
            showCount
            maxLength={500}
            style={{ borderColor: commentError ? 'red' : undefined }}
          />
        </Form.Item>

        <Button
          style={{ position: 'absolute', top: 3, right: 3, width: 18, height: 18 }}
          type="primary"
          ghost
          icon={<IconCheck style={{ width: 14, height: 14 }} />}
          onClick={handleSubmit}
        />

        <Button
          style={{ position: 'absolute', top: 3, right: 23, width: 18, height: 18 }}
          type="danger"
          ghost
          icon={<IconX style={{ width: 14, height: 14 }} />}
          onClick={handleReject}
        />
      </Form>
    </>
  );
};
