import { createSlice, current } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: '',
  selectedProject: null,
  openedActivities: null,
  selectedEmployee: null,
  allProjectsTimesheets: [],
  pageRefresh: false
};

export const { actions: myProjectsTeamsTimesheetsActions, reducer: myProjectsTeamsTimesheetsReducer } = createSlice({
  name: 'myProjectsTeamsTimesheets',
  initialState,
  reducers: {
    setLoading(state, { payload }) {
      state.loading = payload;
    },
    setError(state, { payload }) {
      state.error = payload;
    },
    getAllProjectsTeamsTimesheets(state) {
      state.loading = true;
    },
    getAllProjectsTeamsTimesheetsFulfilled(state, { payload }) {
      state.allProjectsTimesheets = payload;

      if (!state.selectedProject) state.selectedProject = state.allProjectsTimesheets[0]?.projectId;
    },
    getPendingProjectTeamsTimesheet(state) {
      state.loading = true;
    },
    getPendingProjectTeamsTimesheetFulfilled(state, { payload }) {
      let projectTimesheets = [...state.allProjectsTimesheets];
      let projectTimesheet = projectTimesheets.find((project) => payload.projectId === project.project.id);
      projectTimesheet.totalNumberOfHours = payload.totalNumberOfHours;
      projectTimesheet.numberOfRequests = payload.numberOfRequests;
      const projectActivity = projectTimesheet.activities.find((activity) =>
        activity.timesheets.find((timesheet) => timesheet.tpaid === payload.tpaId)
      );
      const timesheets = projectActivity.timesheets.filter((timesheet) => timesheet.tpaid !== payload.tpaId);
      projectActivity.timesheets = [...timesheets];
      const activityIndex = projectTimesheet.activities.findIndex(
        (pta) => pta.activityId === projectActivity.activityId
      );
      if (projectActivity.timesheets.length > 0) {
        projectTimesheet.activities[activityIndex] = projectActivity;
      } else {
        projectTimesheet.activities = projectTimesheet.activities.filter(
          (act) => act.activityId !== projectActivity.activityId
        );
      }
      const projectIndex = projectTimesheets.findIndex((pta) => pta.project.id === projectTimesheet.project.id);
      if (projectTimesheet.activities.length > 0) {
        projectTimesheets[projectIndex] = projectTimesheet;
      } else {
        projectTimesheets = projectTimesheets.filter((pt) => pt.project.id !== projectTimesheet.project.id);
      }
      state.allProjectsTimesheets = [...projectTimesheets];
    },
    RejectPendingProjectTeamsTimesheets(state){
      state.loading=true
    },
    RejectPendingProjectTeamsTimesheetsFulfilled(state, { payload }){
      let projectTimesheets = [...state.allProjectsTimesheets];
      let projectTimesheet = projectTimesheets.find((project) => payload.projectId === project.project.id);
      projectTimesheet.totalNumberOfHours = payload.totalNumberOfHours;
      projectTimesheet.numberOfRequests = payload.numberOfRequests;
      const projectActivity = projectTimesheet.activities.find((activity) =>
        activity.timesheets.find((timesheet) => timesheet.tpaid === payload.tpaId)
      );
      const timesheets = projectActivity.timesheets.filter((timesheet) => timesheet.tpaid !== payload.tpaId);
      projectActivity.timesheets = [...timesheets];
      const activityIndex = projectTimesheet.activities.findIndex(
        (pta) => pta.activityId === projectActivity.activityId
      );
      if (projectActivity.timesheets.length > 0) {
        projectTimesheet.activities[activityIndex] = projectActivity;
      } else {
        projectTimesheet.activities = projectTimesheet.activities.filter(
          (act) => act.activityId !== projectActivity.activityId
        );
      }
      const projectIndex = projectTimesheets.findIndex((pta) => pta.project.id === projectTimesheet.project.id);
      if (projectTimesheet.activities.length > 0) {
        projectTimesheets[projectIndex] = projectTimesheet;
      } else {
        projectTimesheets = projectTimesheets.filter((pt) => pt.project.id !== projectTimesheet.project.id);
      }
      state.allProjectsTimesheets = [...projectTimesheets];
    },
    reviewSelectedProjectTeamsTimesheets(state) {
      state.loading = true;
    },
    reviewSelectedProjectTeamsTimesheetsFulfilled(state, { payload }) {},
    setSelectedProject(state, { payload }) {
      state.selectedProject = payload;
    },
    setOpenedActivities(state, { payload }) {
      state.openedActivities = payload;
    },
    setSelectedEmployee(state, { payload }) {
      state.selectedEmployee = payload;
    },
    setFullActivity(state, { payload: { project, activities, employee } }) {
      state.selectedProject = project;
      state.openedActivities = activities;
      state.selectedEmployee = employee;
    },
    clearSelectedItems(state) {
      state.openedActivities = null;
      state.selectedEmployee = null;
      state.selectedProject = null;
    },
    makeRefreshButtonEnable(state, action) {
      state.pageRefresh = action.payload;
    }
  }
});
