import { createSlice } from "@reduxjs/toolkit";
import { compareAsc, parseISO } from "date-fns";

const initialState = {
  loading: false,
  error: false,
  all: [],
  activeForEdit: [],
  employeeListActiveTab: "1",
  employeePositionCategoryHistory: [],
  activeLineManagers: [],
  lineManagerEmployees: [],
  maxMonthBudgets: [],
  countryList:[],
  TimesheetStatus:[],
};

export const selectEmployeeById = (state, employeeId) => {
  return employeeId ? state.employees.all.find((emp) => emp.id === employeeId) : { fullName: "Not Assigned" };
};

export const selectEmployeeByUserName = (state, employeeUserName) => {
  return state.employees.all.find((emp) => emp.username === employeeUserName);
};

export const selectEmployeeToRemoveByUsername = (state, employeeUserName) => {
  const empToRemove = state.employees.all.find((empBy) => empBy.username === employeeUserName);
  return empToRemove;
};

export const { actions: employeeActions, reducer: employeeReducer } = createSlice({
  name: "employees",
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error, action;
    },
    getEmployees: (state, action) => {
      state.loading = true;
    },
    getEmployeesFulfilled(state, action) {
      const newState = action.payload.data;
      //   JSON.stringify(news1);
      //  const newState = JSON.parse(news1);
      //     const newStateArray = JSON.parse(newState);
      state.all = newState;
    },
    getActiveLineManagers: (state) => {
      state.loading = true;
    },
    getActiveLineManagersFulfilled(state, action) {
      state.activeLineManagers = [...action.payload];
    },
    getSubordinatesByLineManagerId: (state) => {
      state.loading = true;
    },
    getSubordinatesByLineManagerIdFulfilled(state, action) {
      state.lineManagerEmployees = [...action.payload];
    },
    resetSubordinatesByLineManager(state, action) {
      state.lineManagerEmployees = [];
    },
    getActiveEmployeesForEdit: (state) => {
      state.loading = true;
    },
    getActiveEmployeesForEditFulfilled(state, action) {
      state.loading=false;
      state.activeForEdit = [...action.payload];
    },
    
    changeActiveTabEmployeeList(state, action) {
      state.employeeListActiveTab = action.payload;
    },
    patchEmployee: (state) => {
      state.loading = true;
    },
    patchEmployeeFulfilled(state, action) {
      let newEmp = [...state.all];
      const index = newEmp.findIndex((item) => item.id === action.payload.id);
      newEmp.splice(index, 1, action.payload);
      state.all = [...newEmp];
    },
    patchDisactivate(state, action) {
      state.loading = true;
    },
    patchDisactivateFulfilled(state, action) {
      let newEmp = [...state.all];
      let emp = newEmp.find((em) => em.id === action.payload.id);
      emp = { ...emp, reasonOfLeaving: action.payload.reasonOfLeaving, endDate: action.payload.endDate, isActive: 0 };
      const index = newEmp.findIndex((item) => item.id === action.payload.id);
      newEmp.splice(index, 1, emp);
      state.all = [...newEmp];
      state.activeForEdit = state.activeForEdit.filter((emp) => emp.id !== action.payload.id);
    },
    patchActivate(state, action) {
      state.loading = true;
    },
    patchActivateFulfilled(state, action) {
      let newEmp = [...state.all, action.payload];
      newEmp.sort((a, b) => a.fullName.localeCompare(b.fullName));
      state.all = [...newEmp];
      state.activeForEdit = [
        ...state.activeForEdit,
        { id: action.payload.id, fullName: action.payload.fullName, employeeCode: action.payload.employeeCode }
      ].sort((a, b) => a.fullName.localeCompare(b.fullName));
    },
    getEmployeePoscatHistory: (state) => {
      state.loading = true;
    },
    getEmployeePoscatHistoryFulfilled(state, action) {
      state.employeePositionCategoryHistory = [...action.payload];
    },
    postEmployeePoscatHistory: (state) => {
      state.loading = true;
    },
    postEmployeePoscatHistoryFulfilled(state, action) {
      state.employeePositionCategoryHistory = [...state.employeePositionCategoryHistory, action.payload].sort((a, b) =>
        compareAsc(parseISO(b.effectiveDate), parseISO(a.effectiveDate))
      );
    },
    patchEmployeePoscatHistory: (state) => {
      state.loading = true;
    },
    patchEmployeePoscatHistoryFulfilled(state, action) {
      let newEmp = [...state.employeePositionCategoryHistory];
      const index = newEmp.findIndex((item) => item.id === action.payload.id);
      newEmp.splice(index, 1, action.payload);
      state.employeePositionCategoryHistory = [...newEmp];
    },
    deleteEmployeePoscatHistory: (state) => {
      state.loading = true;
    },
    deleteEmployeePoscatHistoryFulfilled(state, action) {
      state.employeePositionCategoryHistory = state.employeePositionCategoryHistory.filter(
        (pch) => pch.id !== action.payload
      );
    },
    delegatePermanentManager: (state) => {
      state.loading = true;
    },
    delegatePermanentManagerFulfilled(state, action) {
      const data = [...state.all];
      const newManEmp = data.map((man) => {
        if (man.manager === action.payload.oldManager) {
          return {
            ...man,
            manager: action.payload.newManager
          };
        } else {
          return man;
        }
      });
      state.all = newManEmp;
    },
    delegateTemporaryManager: (state) => {
      state.loading = true;
    },
    delegateTemporaryManagerFulfilled(state, action) {
      const data = [...state.all];
      const newManEmp = data.map((man) => {
        if (man.manager === action.payload.oldManager) {
          return {
            ...man,
            manager: action.payload.newManager
          };
        } else {
          return man;
        }
      });
      state.all = newManEmp;
    },
    getcountryList: (state) => {
      state.loading = true;
    },
    getcountryListFulfilled(state, action) {
      state.loading=false;
      state.countryList = action.payload
    },
    getTimeSheetStatus(state){
      state.loading=true;
    },
    getTimeSheetStatusFulfilled(state,action){
      state.loading=false;
      state.TimesheetStatus=action.payload
    }
  }
});
