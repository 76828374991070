import { all, put, takeLatest } from 'redux-saga/effects';
import { fetchSaga } from 'utils/fetch-saga';
import { myProjectsTeamsServices } from './my-projects-teams-timesheets.service';
import { myProjectsTeamsTimesheetsActions } from './my-projects-teams-timesheets.slice';

export function* myProjectsTeamsTimesheetsRootSaga() {
  yield all([
    fetchSaga({
      pattern: 'getAllProjectsTeamsTimesheets',
      actions: myProjectsTeamsTimesheetsActions,
      api: {
        method: myProjectsTeamsServices.getAllProjectsTeamsTimesheets,
        callback: (res) => {
          return res.map((resItem) => resItem.data.data).reduce((newArr, item) => [...newArr, ...item], []);
        }
      }
    }),
    fetchSaga({
      pattern: 'getPendingProjectTeamsTimesheet',
      actions: myProjectsTeamsTimesheetsActions,
      api: {
        method: myProjectsTeamsServices.getPendingProjectTeamsTimesheet,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'RejectPendingProjectTeamsTimesheets',
      actions: myProjectsTeamsTimesheetsActions,
      api: {
        method: myProjectsTeamsServices.RejectPendingProjectTeamsTimesheets,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'reviewSelectedProjectTeamsTimesheets',
      actions: myProjectsTeamsTimesheetsActions,
      api: {
        method: myProjectsTeamsServices.submitSelectedPendingProjectTeamsTimesheets,
        callback: (res) => {
          return res.data.data;
        }
      }
    }),
    takeLatest(
      myProjectsTeamsTimesheetsActions.reviewSelectedProjectTeamsTimesheetsFulfilled.type,
      dispatchReloadProjectTimesheetsOnMassReview
    )
  ]);
}

function* dispatchReloadProjectTimesheetsOnMassReview() {
  yield put(myProjectsTeamsTimesheetsActions.getAllProjectsTeamsTimesheets());
}

// /get/all/pendingByProjectDirector
