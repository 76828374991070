import OBJECTS from "casl/objects";
import { all, put, takeLatest } from "redux-saga/effects";
import { pendingEmployeeActions } from "store/pending-employees/pending-employees.slice";
import { fetchSaga } from "utils/fetch-saga";
import { employeeServices } from "./employee.services";
import { employeeActions } from "./employee.slice";

export function* employeeRootSaga() {
  yield all([
    fetchSaga({
      pattern: "getEmployees",
      actions: employeeActions,
      api: {
        method: employeeServices.getEmployees,
        callback: (res) => res.data
      }
    }),
    fetchSaga({
      pattern: "getActiveLineManagers",
      actions: employeeActions,
      api: {
        method: employeeServices.getActiveLineManagers,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: "getSubordinatesByLineManagerId",
      actions: employeeActions,
      api: {
        method: employeeServices.getSubordinatesByLineManagerId,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: "getActiveEmployeesForEdit",
      actions: employeeActions,
      api: {
        method: employeeServices.getActiveEmployeesForEdit,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: "patchEmployee",
      actions: employeeActions,
      api: {
        method: employeeServices.patchEmployee,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: "getEmployeePoscatHistory",
      actions: employeeActions,
      api: {
        method: employeeServices.getEmployeePoscatHistory,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: "postEmployeePoscatHistory",
      actions: employeeActions,
      api: {
        method: employeeServices.postEmployeePoscatHistory,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: "patchEmployeePoscatHistory",
      actions: employeeActions,
      api: {
        method: employeeServices.patchEmployeePoscatHistory,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: "deleteEmployeePoscatHistory",
      actions: employeeActions,
      api: {
        method: employeeServices.deleteEmployeePoscatHistory,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: "delegatePermanentManager",
      actions: employeeActions,
      api: {
        method: employeeServices.delegatePermanentManager,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: "delegateTemporaryManager",
      actions: employeeActions,
      api: {
        method: employeeServices.delegateTemporaryManager,
        callback: (res) => res.data.object
      }
    }),
    fetchSaga({
      pattern: "patchDisactivate",
      actions: employeeActions,
      api: {
        method: employeeServices.patchActivate,
        callback: (res) => res
      }
    }),
    fetchSaga({
      pattern: "getcountryList",
      actions: employeeActions,
      api: {
        method: employeeServices.getcountryList,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: "getTimeSheetStatus",
      actions: employeeActions,
      api: {
        method: employeeServices.getTimeSheetStatus,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: "patchActivate",
      actions: employeeActions,
      api: {
        method: employeeServices.patchActivate,
        callback: (res) => res
      }
    }),
    takeLatest(pendingEmployeeActions.disactivateEmployeeFulfilled.type, dispatchPatchDisactivate),
    takeLatest(pendingEmployeeActions.registerEmployeeFulfilled.type, dispatchPatchActivate)
  ]);
}

function* dispatchPatchActivate(action) {
  const nav = action.payload.nav;
  const emp = yield action.payload.data;
  if (emp) {
    yield put(employeeActions.patchActivate(emp.data.data));
    nav(OBJECTS({}).EMPLOYEE_SETTINGS_GROUP.childrenObjects.EMPLOYEES.path + "/" + emp.data.data.id, { replace: true });
  }
}

function* dispatchPatchDisactivate(action) {
  const nav = action.payload.nav;
  const res = yield action.payload.data;
  if (res) {
    yield put(employeeActions.patchDisactivate(res.data.data));
    nav(OBJECTS({}).EMPLOYEE_SETTINGS_GROUP.childrenObjects.EMPLOYEES.path + "/" + res.data.data.id, { replace: true });
  }
}
